.hamburger-navbar{
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px;
   
    a{
        color: black;
        font-weight: 700;
        line-height: 2.5rem;
    }
}
.text-account{
color: #fff !important;
}