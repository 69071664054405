.fast-action{
    background-color:#F59201;
    background-image: url('04.png');
    border-radius: 5px;
    .light-fast{
        justify-content: center;
        display: flex;
    }
}

.fast-title{
    color: white;
    text-align: center;
    vertical-align:middle;
    font-weight: bold;
    margin-top: 1.1em;
}
.text-fast{
    color: white;
    text-align: center;
}
