.bg-aboutus{
    background-image: url('Qui-sommes-nous.png') ; 
    background-size:cover;
	min-height: 250px;
    max-height: auto;
}
.about-h2{
    color: #F59201;

}
u{
font-family: 'Montserrat', sans-serif !important;
font-family: 'MuseoModerno', cursive !important;


}
.card-com{
    border-top: 10px solid purple !important;
}
.card-inov{
    border-top: 10px solid plum !important;
    margin-top: 5rem
}

.card-pro{
    border-top: 10px solid orangered !important;
}
.card-inc{
    border-top: 10px solid orange !important;
    margin-top: 5rem
}
.card-title-about{
    font-size: 3rem;
    font-weight: bold;
}
.card-subtitle-about{
    color: black !important;
    font-size: 1.4rem !important;
    font-weight: bold;
}

/////@at-root
// .pt-100{
//     padding-top:100px;
// }
// .pb-100{
//     padding-bottom:100px;
// }
// .section-title {
//   margin-bottom: 60px;
  
// }
.section-title p {
	// color: #777;
	font-size: 16px;
}
.section-title h4 {
	text-transform: capitalize;
	position: relative;
	// padding-bottom: 20px;
	// margin-bottom: 20px;
}
// .section-title h4:before {
// 	position: absolute;
// 	content: "";
// 	width: 60px;
// 	height: 2px;
// 	background-color: purple;
// 	bottom: 0;
// 	left: 50%;
// 	margin-left: -30px;
// }
// .section-title h4:after {
// 	position: absolute;
// 	background-color: purple;
// 	content: "";
// 	width: 10px;
// 	height: 10px;
// 	bottom: -4px;
// 	left: 50%;
// 	margin-left: -5px;
// 	border-radius: 50%;
// }
ul.timeline-list {
	position: relative;
	margin: 0;
	padding: 0
}
ul.timeline-list:before {
	position: absolute;
	content: "";
	width: 2px;
	height: 100%;
	background-color: purple;
	left: 50%;
	top: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
ul.timeline-list li {
	position: relative;
	clear: both;
	display:table;
}
.timeline_content {
	border: 0.0px solid #ccc;
	background-color:#fff;
    border-radius: 5px;
}
ul.timeline-list li .timeline_content {
	width: 45%;
	// color: #333;
	padding: 15px;
	float: left;
	// text-align: right;
}
ul.timeline-list li:nth-child(2n) .timeline_content {
	float: right;
	text-align: left;
}
.timeline_content h4 {
	font-size: 22px;
	font-weight: bold;
	// margin: 10px 0;
}
ul.timeline-list li:before {
	position: absolute;
	content: "";
	width: 25px;
	height: 25px;
	background-color: purple;
	left: 50%;
	top: 10%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	border-radius: 50%;
}
.timeline_content span {
	font-size: 18px;
	font-weight: 500;
	font-family: poppins;
	color: purple;
}


///@at-root
.text{
  display: inline-table;
  width: 100%;
}
  
.read-or-hide{
  color: rgb(192,192,192);
  cursor: pointer;
}
.styleCard{
    width: 18rem !important;
}


@media only screen and (max-width: 960px) {
	.card-inov{
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.card-inc{
		margin-top: 1rem
	}
}
.chap1{
	color:purple !important;
}
.chap2{
	color:plum !important;
}
.chap3{
	color:orangered !important;
}
.chap4{
	color:orange !important;
}