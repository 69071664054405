.carousel {
  background-color: white;
}
.col5-author {
  background-color: aqua;
}
.img-author-border {
  border-top: 6px solid purple;
  border-radius: 5px;
}
.img-elearling-border {
  border-top: 3px solid purple;
  border-left: 6px solid purple;
  border-radius: 5px;
}
.img-reading-border {
  border-right: 6px solid purple;
  border-radius: 5px;
}
// .logotype{
//     width: 50px;
//     height: 50px;
// }
.title {
  color: #f59201;
  font-weight: 700;
}
.reading-section {
  background-color: #f5f7f9;
}
.link-more a {
  color: purple;
  font-weight: bold;
}
.logo-mobile {
  width: 126px;
  height: 36px;
}
// .sub-titre{
//   font-size: 60px;
// }
.reading-title {
  color: #f59201 !important;
}
// .carousel-section{
//   // padding-top: 3.5rem;
//   //   padding-bottom: 2.8rem;
//   // height: 350px !important;
// }

.slider-title{
  width:760px;
  height:200px;
  background-color: purple;
  position: absolute;
  margin-top: 17em;
  margin-left: 2em;
  padding: 1.5em;
  border-radius: 5px;
  opacity: 1;

}
// .slider-text{
//   // font-size: 3.1rem;
//   // color: white;
// }

.slick-dots {
  bottom: 0px !important;
  padding: 10px !important;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 50px !important;
  margin: 0 5px;
  padding: 0;
  color: #f59201 !important;
  cursor: pointer;
}
// .slick-dots li button {
//   font-size: 0;
//   line-height: 0;
//   display: block;
//   width: 50px !important;
//   height: 50px !important;
//   padding: 5px;
//   cursor: pointer;
//   color: transparent;
//   border: 0;
//   outline: none;
//   background: transparent;
// }

.slider-text{
  position: absolute;
  top: 40%;
  left: 35%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  text-align: start !important;
  color: white;
  // background-color: aquamarine;
}

.slick-slide {
  /* ... */
  position: relative !important;
}

// mobile
@media only screen and (max-width: 960px) {
  .slider-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    text-align: start !important;
    color: white;
    // background-color: aquamarine;
    width: 100%;
    text-shadow: #f59201 !important;
    padding-left: 2px;
  }
  .slick-slide,.img-sm{
    height: 200px;
    color: #f59201 !important;
  }
  // .d-sm-none{
  //   display: none !important;
  // }
  .timeline_content{
    text-align: start !important;
    border-left: 3px solid purple;
  }
}
//  breakpoint: 1024 main.scss slider
@media only screen and (max-width: 1024) {
  .slick-slide,.img-sm{
    height: 1440px;
    color: #f59201 !important;
  }
}

