.logotype {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
@media only screen and (max-width: 960px) {
  .logotype {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-top: -20px;
  }
  .navbar-expand-xl {
    padding: 0px;
    margin: 0px;
    height: 50px;
  }
}
.NavbarItems {
  background: linear-gradient(
    900deg,
    rgb(110, 94, 254) 0%,
    rgba(73, 63, 252, 1) 100%
  );
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.navbar-logo {
  color: #fff;
  // justify-self: start;
  // margin-left: 20px;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  // text-align: center;
  text-align: start;
  //   padding-left: ;
  width: 70vw;
  //   justify-content: end;
  margin-right: 2rem;
}
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links:hover {
  background-color: #6d76f7;
  border: 4px;
  transition: all 0.2s ease-out;
}
.fa-bars {
  color: #000;
}
+ .navlinks-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1.5rem;
    .nav-link {
      font-weight: 700;
      color: #000;
      letter-spacing: 1px;
      line-height: 2.5rem;
    }
  }
  .nav-menu.active {
    background-color: #f5f5f7;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #6d76f7;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: -20px !important;
  }
  .fa-times {
    color: #000;
    font-size: 1.75rem;
  }
  .nav-link-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background-color: aquamarine;
    text-decoration: none;
    font-size: 1.5rem;
  }
  .nav-link-mobile:hover {
    color: #6568f4;
    background-color: #fff;
    transition: 250m;
  }
  Button {
    display: none;
  }
}
