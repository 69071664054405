.bg-service{
    background-image: url('Nos-services.png') ; 
    background-size:cover;
    min-height: 250px;
    max-height: auto;
  
}
.disabled {
    pointer-events: none;
    cursor: default;
  }
.card_title{
    font-size: 1rem !important;
}
.servic_cover{
    // border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-top: -50px;
    // border: 5px solid orange;
}

.card_cc{
    border-bottom: 5px solid purple;
}
.card_cm{
    border-bottom: 5px solid rgb(251, 38, 251);
}
.card_lrm{
    border-bottom: 5px solid orangered;
}
.card_cla{
    border-bottom: 5px solid orange;
}

.img-other-service{
    width: 100px;
}
.bg-other-service{
    background-color: #f5f7f9 !important;
}