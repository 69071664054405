footer {
  background-color: #0e0410;
  background-image: url('05.png');
}
.text-subinfo {
  color: white;
  margin-top: 5em;
}
.footer-logo {
  justify-content: center;
  display: flex;
}
.logo-footer {
  width: 80px;
  height: 80px;
  border-radius:0px 0px 5px 5px;
}
.text-sub-info{
    display: flex;
    justify-content: center;
}

ul.nav-footer li a{
    font-size: 12px;
    color: #ccc;
}
ul.menu-social li a{
  color: #ccc;
}
ul.nav-footer li a:hover{
color: #F59201;
}
ul.nav-footer li a:focus{
  color: #F59201;
  }
.all{
    font-size: 12px;
    color: #ccc;  
}
.copyright-year{
    color: rgb(151, 149, 149);   
    font-size: 12px;
}
