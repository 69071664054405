.article{
    font-weight: bold;
    font-size: 1.5em;
    color: #6f42c1;
}
.sous-article{
    font-weight: bold;
    color: #f59201;
}
.text-underline{
    
}
section{
    padding: 1.5rem;
}
ul,li{
    line-height: 2.5em;
}