.detail-tarif {
  .number-price {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .text-price {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.card-title-border {
  border-radius: 10% !important;
}
.fixed-content {
}
